
.carouselVid video {
    @apply w-full h-screen object-cover;
}

.carouselVid media-poster shadow-root img {
  @apply w-full h-screen object-cover;
}



/* purgecss start ignore */

.twic {
    opacity: 0;
    will-change: opacity;
    transition: opacity 1s linear, transform .75s ease-out;
  }

  .twic-poster-done {
    opacity: 1;
  }

  .twic-done {
    opacity: 1;
  }

/* purgecss end ignore */