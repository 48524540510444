


@font-face {
    font-family: 'SUF';
    font-style: normal;
    font-weight: 400;
    src:
        url(../fonts/SUFwebtest-Medium.woff2) format("woff2"),
        url(../fonts/SUFwebtest-Medium.woff) format("woff");
}


/* @font-face {
    font-family: 'Bradford';
    font-style: normal;
    font-weight: 400;
    src:
        url(../fonts/BradfordLL-Regular.otf) format("opentype");
}

@font-face {
    font-family: 'Bradford';
    font-style: italic;
    font-weight: 400;
    src:
        url(../fonts/BradfordLL-RegularItalic.otf) format("opentype");
} */