@import "tailwindcss/base";
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,300;0,9..40,400;0,9..40,500;1,9..40,300;1,9..40,400;1,9..40,500&display=swap');
@import "./typography.css";
@import "./video.css";
@import "./defaults.css";

@import "tailwindcss/components";

@import "./layout.css";


@import "tailwindcss/utilities";
/* @import "./vid.css"; */